@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/mixins/mixins.scss';
@import './assets/css/sidebar.scss';
@import './assets/css/mainView.scss';
@import './assets/css/content.scss';
@import './assets/css/search.scss';
@import './assets/css/header.scss';
@import './assets/css/userpanel.scss';
@import './assets/css/dashboard.scss';
@import './assets/css/evaluation.scss';
@import './assets/css/projects.scss';
@import './assets/css/button.scss';
@import './assets/css/tally.scss';
@import './assets/css/button.scss';
@import './assets/css/event.scss';
@import './assets/css/member.scss';
@import './assets/css/colorpicker.scss';
@import './assets/css/box.scss';
@import './assets/css/backButton.scss';
@import './assets/css/pagination.scss';
@import './assets/css/costcenter.scss';
@import './assets/css/toast.scss';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD

@font-face {
  font-family: 'Stolzl';
  src: local('Stolzl'), url(./assets/fonts/Stolzl-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Stolzl Book';
  src: local('Stolzl Book'), url(./assets/fonts/Stolzl-Book.woff) format('woff');
}

@font-face {
  font-family: 'Stolzl Medium';
  src: local('Stolzl Medium'), url(./assets/fonts/Stolzl-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Stolzl Bold';
  src: local('Stolzl Bold'), url(./assets/fonts/Stolzl-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Verdana';
  src: local('Verdana'), url(./assets/fonts/Verdana.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'Stolzl Book';
  src: local('Stolzl Book'), url(./assets/fonts/Stolzl-Book.woff) format('woff');
}

@font-face {
  font-family: 'Stolzl Medium';
  src: local('Stolzl Medium'), url(./assets/fonts/Stolzl-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Stolzl Bold';
  src: local('Stolzl Bold'), url(./assets/fonts/Stolzl-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Verdana';
  src: local('Verdana'), url(./assets/fonts/Verdana.woff) format('woff');
}

@font-face {
  font-family: 'Anonymous';
  src: local('Anonymous'), url(./assets/fonts/Anonymous_Pro_B.ttf) format('ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Stolzl';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --white: #ffffff;
  --grayBorder: #eef0f4;
  --mainColor: #232932;
  --lightGray: #dedede;
  --lightGrayBorder: #eeeeee;
  --lightestGray: #f8f9fa;
  --gray: #d5d5d5;
  --inputBorder: #eaeaea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.h-100 {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  @apply text-[14px] text-[#232932] leading-[17px] border-[1px] border-[#eaeaea] font-stolzlBook block rounded-default border-solid;
}

textarea {
  @apply border-[1px] border-[#eaeaea] text-[12px] text-[#232932] leading-[17px] font-stolzlBook border-solid rounded-default;
}

.loading {
  .circles {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: block;

    span {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: block;
      position: absolute;
      background: var(--mainColor);
      animation: loadingCenterCirles 1s infinite linear;
      animation-delay: .3s;
      transform: scale(0);
    }

    &:before, &:after {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 100%;
      display: block;
      position: absolute;
      background: var(--mainColor);
    }

    &:before {
      transform: scale(0);
      left: 0;
      animation: loadingCenterCirles 1s infinite linear;
      animation-delay: 0s;
    }

    &:after {
      transform: scale(0);
      right: 0;
      animation: loadingCenterCirles 1s infinite linear;
      animation-delay: .6s;
    }
  }
}

@keyframes loadingCenterCirles {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}