.boss-evaluation__container {
    width: 100%;
  }
  .boss-evaluation__heading {
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .boss-evaluation-section__heading {
    font-size: 12px;
    margin-bottom: 20px;
    font-weight: 100;
    padding: 20px 0 0 20px;
  }
  
  .boss-evaluation__heading__inner {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      font-size: 11px;
    }
  }
  
  .boss-evaluation__wrapper {
    width: 100%;
    border: 0.5px solid lightgray;
    @media screen and (max-width: 767px) {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
  .boss-evaluation__wrapper_inner {
    @media screen and (max-width: 767px) {
      width: 767px;
      padding: 15px;
    }
  }

.boss-evaluation__input {
  width: 35px;
  text-align: center;

  &:focus {
    + .boss-score {
      opacity: 0;
    }
  }

  &:hover {
    + .boss-score {
      border-color: #eaeaea;
    }
  }

  &.error {
    + .boss-score {
      opacity: 0;
    }
    
    border-color: #f8d7da;
    box-shadow: 0 0 5px #f8d7da;
  }

  &.boss-scored {
    background-color: #f8f9fa;
  }
}

.boss-evaluation__button {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 30px 30px 0;
}