.user__section {
  margin-top: 10px;
  padding: 10px 0;

  .user__section--heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.filter__content {
  padding: 30px;
  background-color: var(--lightestGray);

  .filter__content--search {
    display: flex;
    justify-content: space-between;

    .search {
      flex-grow: 1;
      max-width: 50%;
    }
  }

  .filter__title {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    color: var(--lightGray);
    text-transform: uppercase;
  }

  .filter__content--filter {
    margin-top: 10px;
    display: flex;

    & > div {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.information__content {
  padding: 30px;
  margin-top: 20px;
  background-color: var(--lightestGray);

  .information__content--row {
    display: flex;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .information__content--col {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.info-details__content {
  padding: 30px;
  margin-top: 20px;
  background-color: var(--lightestGray);

  .info-details__content--row {
    display: flex;
    flex-wrap: wrap;

    &.no-wrap {
      flex-wrap: nowrap;
    }

    &:not(:first-child) {
      margin-top: 20px;
    }

    .info-details__content--col {
      word-break: break-word;

      &:not(:last-child) {
        margin-right: 50px;
      }

      @media (max-width: 673px) {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }

  .info-details__content--action-row {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 767px) {
      margin-top: 20px;
    }

    @media (max-width: 390px) {
      justify-content: center;
    }
  }

  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    overflow: hidden;
    position: relative;

    .loading & {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #cbcbcb;
      }
    }

    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
      align-self: center;
    }
  }

  .labeled-text:not(:first-child) {
    margin-top: 20px;
  }
}

.skillset-tab,
.project-tab,
.team-tab {
  width: 100%;
  overflow: auto;
}
.skillset-table__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 400px) {
    display: block;
  }

  .button__text {
    //padding: 0;
    padding-left: 15px;
  }

  button {
    @media (max-width: 400px) {
      margin-top: 10px;
    }
  }
}
.project-statistics {
  padding: 30px;
  margin-top: 20px;
  background-color: var(--lightestGray);
  display: flex;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  * {
    flex-grow: 1;
  }

  .labeled-text {
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

.team-tab {
  .team-tab__actions {
    display: flex;
    justify-content: flex-end;
  }

  .team-tab__content {
    margin-top: 20px;
    padding: 30px;
    background-color: var(--lightestGray);

    .team-tab__row {
      display: flex;
      flex-wrap: wrap;

      .team-tab__col {
        flex-grow: 1;
        margin: 0 0 25px;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }

        &:not(:first-child) {
          @media (max-width: 767px) {
            margin-top: 20px;
          }
        }

        .labeled-text:not(:first-child),
        .input__label:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
}

.other-info-tab {
  width: 100%;
  overflow: auto;

  .other-info__section {
    &:not(:first-child) {
      margin-top: 30px;
    }

    .other-info__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .other-info__content {
      margin-top: 20px;
      padding: 30px;
      background-color: var(--lightestGray);

      .other-info__row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 767px) {
          flex-wrap: wrap;  
        }

        &:not(:first-child) {
          padding-top: 15px;
          border-top: 1px solid var(--lightGray);
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        .other-info__col {
          width: 250px;

          &:not(:last-child) {
            margin-right: 20px;
          }

          &:not(:first-child) {
            @media (max-width: 767px) {
              margin-top: 20px;
            }
          }

          .labeled-text:not(:first-child),
          .input__label:not(:first-child) {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.login-tab {
  width: 100%;
  overflow: auto;

  .login-tab__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .login-button {
    @media (max-width: 767px) {
      margin: 20px 0 0;
    }

    .button {
      @media (max-width: 767px) {
        padding: 12px 20px;
      }
    }
  }

  .login-tab__content {
    margin-top: 20px;
    padding: 30px;
    background-color: var(--lightestGray);

    .login-tab__row {
      display: flex;

      @media (max-width: 767px) {
        flex-wrap: wrap;
        flex-direction: column;
      }

      .login-tab__col {
        flex-grow: 1;
        
        @media (max-width: 767px) {
          margin-bottom: 20px;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }

        .labeled-text:not(:first-child) {
          margin-top: 30px;
        }
      }
    }
  }
}

.login-tab__checkbox {
  display: flex;
  align-items: center;
  position: relative;

  label {
    font-size: 12px;
    position: absolute;
    cursor: pointer;
    top: 0;
  }

  .yes {
    background-color: #21af07;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 10px;
    color: #fff;
    margin: 10px 0 0;
  }

  .no {
    background-color: #e83030;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 10px;
    color: #fff;
    margin: 10px 0 0;
  }

  input[type=checkbox].switch + span{
    width: 34px;
    height: 14px;
    background: lightgray;
    display: inline-block;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin: 0;
    &:before,
    &:after{
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: white;
      border-radius: 50%;
      position: absolute;
      top: -3px;
      left: 0;
      transition: all .2s;
    }
    &:after{
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
    &:before{
      background: lightgray;
      transform: scale3d(0,0,1);
    }
    &:active:before{
      transform: scale3d(3,3,1);
      opacity: 0;
    }
  }
  input[type=checkbox].switch{
    display: none;
    &:checked + span{
      background: rgba(#21af07, 0.5);
      &:before,
      &:after{
        left: 14px;
        background: #21af07;
      }
    }
    &:disabled + span{
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.login-tab__checkbox-label {
  font-size: 12px;
}

.login-tab__exempt-status {
  color: #fff;
  margin: 5px 0 0;

  .yes {
    background-color: #21af07;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 10px;
  }

  .no {
    background-color: #e83030;
    font-size: 12px;
    padding: 3px 6px;
    border-radius: 10px;
  }
}

.login-tab__checkbox-toggle {
  width: 55px;
  outline: none;

  span {
    transition: all 0.2s ease;
  }

  &:before {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    border-radius: 100%;
    top: -2px;
    transition: all 0.2s ease;
    left: 0;
    background-color: #e83030;
    box-shadow: 0 0 5px #2d2d2d;
    z-index: 2;
  }

  &.yes {
    &:before {
      left: 30px;
      background-color: #00bc00;
      box-shadow: 0 0 5px #025919;
    }

    span {
      display: block;
      position: relative;
      left: -1px;
      width: 24px;
    }
  }

  &.no {
    span {
      display: block;
      position: relative;
      left: 23px;
      width: 20px;
    }
  }
}