.activeProjects__container {
  position: relative;
  margin-top: 37px;
  border: 1px solid var(--lightGrayBorder);

  &.no-border {
    border: none;
  }
}

.activeProjects__wrapper {
  padding: 30px;

  &.no-padding {
    padding: 0;
  }
}

.activeProjects__inner {
  margin-top: 18px;
}

.activeProjects__content {
  background-color: var(--lightestGray);
  padding: 30px 33px;

  &:not(:first-child) {
    margin-top: 18px;
  }
}

.activeProjects__column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.activeProjects__row {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.activeProjects__label {
  font-size: 12px;
}

.activeProjects__text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
  text-transform: capitalize;
}

.activeProjects__memberImage {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50px;
}

.activeProjects__members-img {
  width: 70px;
  height: 70px;
  //overflow: hidden;
  border-radius: 50%;
  object-fit: cover;

  .activeProjects__memberImage {
    width: 100%;
    border: 1px solid #eaeaea;
  }

  .activeProjects__memberImage_bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    width: 70px;
    height: 70px;
  }
}

.activeProjects__members {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.activeProjects__role {
  font-size: 12px;
}

.activeProjects__member {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
}

.activeProjects__memberInfo {
  margin-left: 20px;
}

.activeProjects__texts {
  font-size: 12px;
}

@media screen and (max-width: 820px) {
  .activeProjects__members {
    padding: 10px 0;
  }
}

@media screen and (max-width: 767px) {
  .activeProjects__column {
    flex-direction: column;
    position: relative;
  }

  .activeProjects__row {
    padding-bottom: 10px;
  }

  .activeProjects__members {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 425px) {
  .activeProjects__members {
    display: revert;
    margin: 0 auto;
  }

  .activeProjects__memberInfo {
    margin-left: 0px;
  }
  
  .activeProjects__memberImage {
    margin-left: 16px;
  }
}

@media screen and (max-width: 375px) {
    .activeProjects__content {
      padding-bottom: 9px;
    }

    .activeProjects__row.sp_view {
      margin: 20px 0;
    }
}

@media screen and (max-width: 320px) {
  .activeProjects__row.sp_view {
    margin: 10px 0;
  }
}