.evaluation {
  .button {
    font-size: 12px;
    letter-spacing: 0;
    border-radius: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .tab__container {
    margin-top: 26px;
    height: 100%;

    .tab__content-container {
      padding-top: 0;
    }
  }

  .tab__content {
    &.active-content {
      padding: 38px 0 43px;
      background-color: transparent;
    }
  }

  .evaluation__table {
    h2 {
      font-weight: bold;
    }
  }
}

.evaluation__heading {
  position: relative;

  h1 {
    font-size: 20px;
    letter-spacing: 0;
  }

  h3 {
    font-size: 16px;
    letter-spacing: 0;
  }

  &.evaluation__heading--inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
  }
}

.evaluation__section {
  margin-top: -5px;
  padding: 0;
  width: 100%;

  &--filled {
    padding: 30px;
    background-color: var(--lightestGray);
    margin-top: 30px;

    &.evaluation-sheet__dropdown {
      padding: 17px 30px;
      margin-top: 37px;

      p {
        font-size: 16px;
        font-weight: 600;
      }

      img {
        width: 10px;
        height: 10px;
      }
    }

    .evaluation__section--row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .evaluation__section--col {
        flex-grow: 1;
      }
    }
  }

  &.mt30 {
    margin-top: 30px;
  }

  .evaluation__section--heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;
  }

  .evaluation__template {
    display: flex;
    padding: 10px;
    align-items: center;

    .evaluation__template-item {
      padding: 0 10px;

      p {
        font-size: 12px;
      }
    }
  }

  .evaluation__section--row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.evaluation-information__content {
  padding: 30px;
  margin-top: 20px;
  background-color: var(--lightestGray);

  .evaluation-information__content--row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .evaluation-information__content--col:not(:last-child) {
      margin-right: 50px;
    }
  }
}

.evaluation-list__content {
  padding: 30px;
  margin-top: 20px;
  background-color: var(--lightestGray);

  .evaluation-list__content--row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 20px;
    }

    .evaluation-list__content--col:not(:last-child) {
      margin-right: 50px;
    }
  }

  .evaluation-filter__content--search {
    display: flex;
    justify-content: space-between;

    .search {
      flex-grow: 1;
      max-width: 50%;
    }
  }

  .evaluation-filter__title {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    color: var(--lightGray);
    text-transform: uppercase;
  }

  .evaluation-filter__content--filter {
    margin-top: 10px;
    display: flex;

    & > div {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}

.evaluation__edit {
  position: absolute;
  right: 0;
  top: 50%;
  border: none;
  font-weight: bold;
  transform: translateY(-50%);
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  span {
    padding-left: 2px;
    width: 10px;
    height: 24px;
    position: relative;
    display: flex;

    &:before {
      position: absolute;
      content: '...';
      display: block;
      font-size: 22px;
      transform: rotate(90deg);
    }
  }
}

.evaluation__previous-page {
  display: flex;
  align-items: center;
  font-size: 22px;
}

.evaluation__container {
  width: 100%;

  &.evaluation__container--inner {
    margin-top: 30px;
  }
}

.evaluation__breadcrumbs {
  margin-top: 18px;
  font-size: 12px;
  color: var(--gray);
}

.evaluation__breadcrumbs-list {
  list-style: none;
  display: flex;
}

.evaluation__breadcrumbs-item {
  &:not(:last-child) {
    &:after {
      display: inline-block;
      content: '>';
      padding: 0 3px;
    }
  }

  a {
    &:link,
    &:visited {
      color: var(--gray);
    }
  }
}

.evaluation__list {
  display: flex;
  padding: 19px 0;
  justify-content: space-between;
  border-bottom: 1px dashed var(--lightGrayBorder);

  &:last-child {
    border-bottom: 2px solid var(--lightGrayBorder);
  }
}

.evaluation__quarter {
  font-size: 14px;
  flex: 75%;

  span {
    display: block;
  }
}

.evaluation__status {
  position: relative;
  font-size: 14px;
  flex: 25%;
  white-space: nowrap;

  &::before {
    position: absolute;
    left: -16px;
    top: 2px;
    content: url('../icons/warning.svg');
  }
}

.evaluation__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.evaluation__button {
  &.evaluation__button--save-section {
    margin-top: -11px;
  }

  &.evaluation__button--save-field {
    margin-top: -7px;
    margin-left: -7px;

    .button {
      padding: 12px 20px;
      opacity: 1;
      transition: opacity 0.25s ease-in-out;

      &.disabled {
        opacity: 0.3;
        transition: opacity 0.25s ease-in-out;
      }

      .button__text {
        padding-left: 1px;

        &:before {
          left: 14px;
        }
      }
    }
  }

  &.evaluation__button--kebab {
    position: relative;
    display: inline-block;
    width: 4px;
    height: 16px;
    border: none;
    opacity: 1;
    background-color: transparent;
    transition: opacity 0.3s ease-in-out;

    &:before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 16px;
      background-position: top center;
      background-repeat: no-repeat;
      background-image: url('../icons/kebab-menu.svg');
      background-size: contain;
    }

    &:hover {
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.evaluation__kebab {
  position: relative;

  &.evaluation__kebab--question {
    margin-right: 5px;
  }
}

.evaluation__tooltip-arrow {
  position: absolute;
  top: 45px;
  right: 2px;
  z-index: 1;
  width: 55px;
  height: 46px;
  background-color: #fff;
  transform: skewY(-39deg);
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
}

.evaluation__tooltip-menu {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  padding: 21px 19px;
  border-radius: 10px;
  pointer-events: none;
  background-color: var(--white);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;

  &.evaluation__tooltip-menu--active {
    top: 43px;
    right: 2px;
    opacity: 1;
    pointer-events: auto;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      right: 0;
      width: 24px;
      height: 22px;
      background-color: #ffffff;
      transform: skewY(-39deg);
      z-index: 1;
    }
  }
}

.evaluation__tooltip-menu-list {
  list-style: none;
  font-size: 12px;
  width: 161px;
  max-width: 100%;
}

.evaluation__tooltip-menu-list-item {
  padding: 8px 10px 7px;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--lightestGray);
    transition: background-color 0.3s ease-in-out;
  }
}

.evaluation__content {
  margin: 0 auto;
  width: 100%;

  &.evaluation__content--schedule,
  &.evaluation__content--template {
    margin-top: 40px;
  }

  // &.evaluation__content--schedule {}
  // &.evaluation__content--template {}

  .sort-table__container-table {
    margin-top: 20px;
  }
}

// .evaluation__filter {}
// .evaluation__filter-content {}
// .evaluation__filter-search {}

.evaluation__filter-schedule {
  padding-bottom: 23px;
}

.evaluation__filter-search-title {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  color: var(--lightGray);
  text-transform: uppercase;
}

.evaluation__label {
  font-size: 12px;
  color: var(--mainColor);
}

.evaluation__input {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  font-family: 'Stolzl';
  font-size: 14px;
  border: 1px solid var(--inputBorder);
  border-radius: 10px;

  &.error {
    border: thin solid rgba(red, 0.5);
  }

  &.evaluation__input--date {
    padding: 8px;
    text-transform: uppercase;
  }

  &.evaluation__input--select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-image: url('../icons/dropdown.svg');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 10px;
    background-origin: content-box;
  }

  &.evaluation__input--textarea {
    min-height: 100px;
    resize: none;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.evaluation__save-button {
  opacity: 1;
  padding: 12px 28px;
  border-radius: 10px;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.3;
    transition: opacity 0.25s ease-in-out;

    &:hover {
      cursor: auto;
    }
  }

  .button__text {
    font-size: 12px;
    padding-left: 32px;
    padding-right: 12px;

    &:before {
      left: 38px;
    }
  }
}

.evaluation__row {
  display: flex;
  width: 100%;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;

    &.nowrap-sp {
      flex-wrap: nowrap;
    }
  }

  &:not(:first-child) {
    padding-top: 23px;

    &.pt0 {
      padding-top: 0;
    }
  }

  &.--column {
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &.--nowrap {
    flex-wrap: nowrap;

    @media screen and (max-width: 509px) {
      flex-wrap: wrap;
    }
  }

  &.mb0 {
    &:first-child {
      margin-bottom: 0;
    }
  }

  &.alignCenter {
    align-items: center;
  }

  &.evaluation__row--information {
    height: 39.5px;

    @media screen and (max-width: 575px) {
      height: 100%;
      flex-wrap: wrap;
    }

    @media screen and (min-width: 576px) and (max-width: 767px) {
      height: 100%;
      flex-wrap: nowrap;
    }
  }
}

.evaluation__column {
  width: 100%;

  &.mr30 {
    margin-right: 30px;
  }

  &.evaluation__column--two {
    width: calc(100% / 2);

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .evaluation__row {
      padding-top: 5px;
    }

    &:not(:last-child) {
      margin-right: 30px;

      @media screen and (max-width: 767px) {
        margin-right: 0;
        padding-bottom: 23px;
      }
    }

    &.center {
      display: flex;
      align-items: center;
    }

    &.two {
      width: calc(50% / 2);

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &.four {
      width: calc(50% / 4);
    }

    &.six {
      width: calc(50% / 6);
    }

    &.seventy-six {
      width: 76.6%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &.seventy {
      width: 70%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &.eighty {
      width: 80%;
    }

    &.twenty {
      width: 20%;
    }

    &.reverse {
      &:last-child {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  &.evaluation__column--three {
    width: calc(100% / 3);

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &:not(:first-child) {
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.xl {
      width: 257%;
      margin-right: 80px;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }

    &.forty {
      width: 40.33%;
      margin-right: 20px;

      @media screen and (max-width: 767px) {
        width: 0;
      }
    }

    &.two {
      width: 2.6%;
    }
  }

  &.evaluation__column--four {
    width: calc(100% / 4);

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &.evaluation__column--six {
    width: calc(100% / 6);
    flex: 0 0;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &.evaluation__column--sixty {
    padding-top: 10px;
    width: 60%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.evaluation__information {
  margin: 0;

  &.evaluation__information--form {
    padding-top: 21px;
  }

  &.evaluation__information--note {
    padding-top: 39px;
  }

  h2 {
    font-size: 16px;
  }

  h3 {
    font-size: 14px;
    font-weight: bold;
  }

  p {
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: 0;
  }
}

.evaluation__score-weights {
  display: flex;
  flex-wrap: wrap;

  &.read-only {
    width: 80%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.evaluation__score-weights-item {
  flex: 0 0 0;

  &.read-only {
    flex: 1 1;

    @media screen and (max-width: 767px) {
      flex: 1 0;
    }
  }
}

.evaluation__weights-list {
  display: flex;
  align-items: center;

  &.pt10 {
    margin: 0 20px;
    padding-top: 10px;

    @media screen and (max-width: 767px) {
      margin: 0 18px;
    }
  }
}

.evaluation__weights-item {
  flex: 0 0;

  &:not(:last-child) {
    margin-right: 1px;
  }

  &.-input {
    flex: 0 0 50px;
  }

  .evaluation__input {
    margin-top: 0;
    text-align: center;
  }
}

.evaluation__weights {
  div {
    display: inline-block;
  }
}

// Evaluation Template
.evaluation__template,
.evaluation__schedule {
  padding-top: 20px;
}

.evaluation__template-save {
  display: flex;
  align-items: center;

  button + button {
    margin-left: 10px;
  }

  p {
    margin-right: 10px;
  }

  @media screen and (max-width: 459px) {
    flex-wrap: wrap;

    button {
      width: 100%;
    }

    button + button {
      margin-top: 10px;
      margin-left: 0;
    }
  }
}

.evaluation__template-overview,
.evaluation__schedule-overview {
  .label-text-item {
    width: 100%;
  }
}

.evaluation__template-overview-list {
  list-style: none;
  display: flex;
  margin-top: 0;

  &.mt20 {
    margin-top: 20px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.evaluation__template-overview-item {
  width: calc(100% / 4);

  &:not(:first-child) {
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }

  &.two {
    width: calc(100% / 2);

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 30px;
      // @media screen and (max-width: 767px) {}
    }
  }

  &.three {
    width: calc(100% / 3);

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &.full {
    width: 100%;
  }
}

.evaluation__template-information {
  span {
    position: relative;
    display: block;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.evaluation__template-information-label {
  font-size: 12px;
  font-weight: normal;
}

.evaluation__template-information-value {
  padding-top: 5px;
  font-size: 14px;
  font-weight: bold;
  min-height: 22px;

  @include loading;

  &.description {
    white-space: pre-wrap;
  }
}

.evaluation__template-section {
  margin-top: 40px;
}

.evaluation__template-section-list {
  &.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
  }
}

.evaluation__template-add-section,
.evaluation__template-add-question {
  button {
    &:hover {
      cursor: pointer;
    }
  }
}

.evaluation__template-add-section {
  padding-top: 40px;

  &.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;

    button {
      &:hover {
        cursor: auto;
      }
    }
  }
}

.evaluation__template-add-field {
  padding-top: 0; //20px;

  &.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;

    button {
      &:hover {
        cursor: auto;
      }
    }
  }
}

.evaluation__template-add-subsection {
  padding-top: 20px;

  &.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;

    .button__add {
      &:hover {
        cursor: auto;
      }
    }
  }
}

.evaluation__template-section-content {
  margin-top: 9px;
}

.evaluation__template-subsection-container {
  margin-top: 30px;
}

.evaluation__template-subsection-list {
  list-style: none;

  &.pb20 {
    padding-bottom: 20px;
  }

  &.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;

    .evaluation__save-button {
      user-select: none;
      pointer-events: none;
    }

    .evaluation__template-subsection-item {
      .evaluation__template-add-field {
        button {
          user-select: none;
          pointer-events: none;
        }
      }
    }
  }
}

.evaluation__template-subsection-item {
  margin-top: 10px;

  &:not(:first-child) {
    margin-top: 20px;
  }
}

.evaluation__template-subsection-edit {
  position: absolute;
  top: 0;
  right: 0;
}

.evaluation_modal {
  width: 700px!important;
  max-height: 700px!important;
  overflow-y: scroll!important;

  @media screen and (max-width: 767px) {
    width: 100%!important;
  }
}

.evaluation_modal_content {
  padding: 30px 10px;
}

.evaluation_modal_text_margin {
  margin-top: 5px;
}

.evaluation_modal_heading {
  font-weight: bold;
  font-size: 20px;
}

.evaluation_modal_subheading {
  font-weight: bold;
}

.evaluation_modal_label {
  font-weight: bold;
  text-transform: capitalize;
}

.evaluation_modal_detail_heading {
  text-transform: capitalize;
}

.evaluation_modal_details {
  text-transform: capitalize;
  margin-top: 5px;
  margin-left: 30px;
  font-size: 14px;
}

.evaluation_modal_button {
  display: flex;
  justify-content: flex-end;

  .button__text {
    padding: 0;
  }
}


// DEBUGGER
@mixin debug($zIndex: auto) {
  box-shadow: inset 0px 0px 0px 1px rgb(255, 0, 0);
  background-color: rgba(255, 0, 0, 0.15);
  z-index: $zIndex;
}

.debug {
  @include debug;
}
