

.rbc-calendar { 
    height: 800px; 
    @media screen and (max-width: 767px) {
        height: 408px;
    }
  }

.date-in-past {
  width: 14.3%;
  background: #ccc;
  border-right: solid 1px #fff;
 }

 .rbc-off-range-bg {
    background: #fff;
 }

 .rbc-show-more {
    @media screen and (max-width: 767px) {
        font-size: 9px;
    }
 }

 .rbc-today {
    background: #F0F0F0;
 }

 .rbc-date-cell {
    text-align: left;
    padding-left: 5px;
    font-size: 12px;
    @media screen and (max-width: 767px) {
        font-size: 8px;
    }
 }

 #calendarpicker {
    font-size: 12px;
    max-width: 140px;
    border-radius: 4px;
    @media screen and (max-width: 767px) {
        max-width: none;
        font-size: 14px;
        padding: 10px;
    }
    
 }

 .calendarPicker input {
    float: right;
 }

 .rbc-header {
    background: #F0F0F0;
    border-bottom: 1px solid #F0F0F0;
 }


 .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #F0F0F0;
}

.rbc-month-view {
    border: 1px solid #F0F0F0;
}

.rbc-header {
    font-size: 12px;
    @media screen and (max-width: 767px) {
        font-size: 8px;
    }
}

.rbc-header + .rbc-header {
    border-left: 1px solid #F0F0F0;
    font-size: 12px;
    @media screen and (max-width: 767px) {
        font-size: 8px;
    }
}

.rbc-month-row + .rbc-month-row {
    border-top: 1px solid #F0F0F0;
}

.rbc-event-content {
    text-align: center;
    font-size: 10px;
    padding: 0;
    @media screen and (max-width: 767px) {
        font-size: 6px;
    }
}

.rbc-overlay {
    padding: 10px;
    min-width: 160px !important;
    @media screen and (max-width: 767px) {
        padding: 5px;
    }
}

.rbc-overlay-header {
    font-size: 12px;
    text-align: center;
    width: min-content;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 0;
    @media screen and (max-width: 767px) {
        font-size: 6px;
        padding-top: 10px;
    }
}


.rbc-overlay .rbc-event-content {
    font-size: 9px;
    @media screen and (max-width: 767px) {
        font-size: 6px;
    }
}

.rbc-toolbar button {
    background: #F0F0F0;
    padding: 6px 8px;
    @media screen and (max-width: 767px) {
        padding: 9px 11px;
    }
    
}

.prev-button button {
    border-radius: 5px 0 0 5px;
}

.next-button button {
    border-radius: 0 5px 5px 0;
}

.today-button button {
    padding: 4px 8px;
    font-size: 10px;
    @media screen and (max-width: 767px) {
        padding: 7px 14px;
        font-size: 12px;
    }
}

.rbc-toolbar {
    font-size: 14px;
}

.rbc-button-link {
    font-weight: normal;
}

.announcement-border {
    min-height: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    padding: 1px 5px;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.clock-text {
    font-family: 'Anonymous';
}