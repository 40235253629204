.tab__content {    
  &.is-project-team {
    flex-direction: column;
  }
}

.project-team__container:not(:first-child) {
  margin-top: 20px;
}

.project-team__container {
  width: 100%;
  padding: 30px;
  background-color: var(--lightestGray);
  overflow: hidden;
}

.project-team__item {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 30px 0 0;
  text-transform: capitalize;

  @media screen and (max-width: 767px) {
    margin: 5px 0 0 0;
  }
}

.project-team__row {
  display: flex;
  margin-bottom: 15px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin: 0;
  }

  @media screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.project-team__column {
  flex: 1 0 27%;

  @media screen and (max-width: 767px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 1200px) {
    margin-bottom: 15px;
    flex: 1 0 50%;
  }
}

.project-team__member {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 240px;
  overflow: auto;

  @media screen and (max-width: 767px) {
    max-height: unset;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    max-height: 440px;
  }
}

.project-team__label {
  font-size: 12px;
  text-transform: capitalize;
}

.project-team__description {
  font-size: 14px;
  font-weight: bold;
  margin: 3px 0 20px;
}
