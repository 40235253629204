.ReactModalPortal {
  position: relative;
  z-index: 999;
}

.ReactModal__CustomOverlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__CustomContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  max-height: 100%;
  
}

.ReactModal__CloseButton {
  background-color: transparent;
  border: none;
  font-size: 30px;
  font-weight: 700;
  line-height: 0.5;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}


.ReactModal__Body--open {
  overflow: hidden;
}
