.project-details__section {
  margin-top: 30px;
  padding: 10px 0;
}

.project-details__heading--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.basic-information {
  .basic-information__content {
    padding: 30px;
    margin-top: 15px;
    background-color: var(--lightestGray);
  }

  .basic-information__industry {
    display: flex;
    align-items: center;

    .basic-information__industry--select {
      flex-grow: 1;
      max-width: 350px;
    }
  }
}

.project-members {
  .project-members__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.basic-information {
  .basic-information__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
