.input__label {
  position: relative;
  font-size: 12px;
  width: 100%;
}

.input {
  display: block;
  width: 100%;
  min-width: 150px;
  max-width: 250px;
  margin-top: 5px;
  padding: 10px;
  font-family: 'Stolzl';
  font-size: 14px;
  border: 1px solid var(--inputBorder);
  border-radius: 10px;

  &--full-width {
    min-width: auto;
    max-width: none;
  }

  &.input__date {
    padding: 8px;
    text-transform: uppercase;
  }

  &.input__select {
    padding: 10px;
    margin-right: 20px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    background-color: var(--white);
    background-image: url('../icons/dropdown.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
    background-origin: content-box;

    &:hover {
      cursor: pointer;
    }
  }

  &.input__multi-select {
    background-color: var(--white);
    background-image: url('../icons/dropdown.svg');
    background-repeat: no-repeat;
    background-position: 98% center;
    background-origin: content-box;

    .input__multi-select--label {
      background-image: url('../icons/dropdown.svg');
      background-repeat: no-repeat;
      background-position: 98% center;
      background-origin: content-box;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        color: blue;
        font-size: 12px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .input__multi-select--dropdown {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100px;
      max-height: 300px;
      border: 1px solid var(--inputBorder);
      border-radius: 10px;
      background-color: var(--white);

      &.is-visible {
        display: block;
        z-index: 1;
      }

      .input__multi-select--label {
        padding: 10px;
      }

      .input__multi-select--options {
        max-height: 250px;
        overflow: auto;

        .input {
          padding-top: 0px;
        }
      }
    }
  }

  &.input__textarea {
    min-height: 120px;
    resize: none;
  }

  &.input__checkbox {
    display: flex;
    align-items: center;
    border: none;

    .input__checkbox--marker {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      width: 17px;
      font-size: 12px;
      cursor: pointer;
      padding: 1px;
      border-radius: 4px;
      background-color: var(--white);
      border: 1px solid var(--inputBorder);
    }

    .input__checkbox--label {
      font-size: 12px;
      margin-left: 7px;
      cursor: pointer;
    }
  }
}
.custom__checkbox {
  input[type=checkbox] {
    &:checked ~ span {
      background: #232932;
      border-color: #232932;
    }
  }
}

.custom__td  {
  &:last-child button{
    border: 0;
  }
  button {
    border-right: #232932 1px solid;
    padding: 0 5px;
  }
}

.custom__radio {   
   input[type=radio] + label span {
       transition: background .2s,
         transform .2s;
   }
   
   input[type=radio] + label span:hover,
   input[type=radio] + label:hover span {
     transform: scale(1.2);
   } 
   
   input[type=radio]:checked + label span {
     background-color: #000000; 
     border-color: #000000;
     box-shadow: 0px 0px 0px 2px white inset;
   }
   
   input[type=radio]:checked + label{
      color: #000000; //text-blue
   }
   
   
}

.custom__details {
  summary {
		list-style: none;

		&:focus {
			outline: none;
		}

		&:hover .summary-chevron-up svg {
			opacity: 1;
		}
	}

  .summary-chevron-up,
	.summary-chevron-down {
		pointer-events: none;
		position: absolute;
		top: 16px;
		right: 30px;
		background: #F7F7F7;

		svg {
			display: block;
		}
	}

  summary::-webkit-details-marker {
		display: none;
	}
}
.holiday__container .search-filter__label {
  z-index: 0;
}
.holiday-checkbox {
  width: auto !important;
  margin-bottom: 0 !important;
}
#yearPicker::-webkit-calendar-picker-indicator {
  display: none;
}
.custom-select {
  -moz-appearance:none; 
  -webkit-appearance:none; 
  appearance: none;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  color: #232932;
  font-size: 12px;
  padding: 10px;
  max-width: 100%;
  background-image: url('../icons/arrow-down.svg');
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 16px;
  background-size: 6%;
  width: 140px;
  position: relative;
}

// Search input custom css
.dropdown {
  list-style-type: none;
  padding: 0;
}

/* Styling for each dropdown item */
.dropdown li {
  padding: 8px 12px;
  cursor: pointer;
}

/* Highlighted style when an item is selected */
.dropdown li.selected {
  background-color: #E8F1FF60;
  transition-duration: 200ms;
}

/* Example of indicator when arrow keys are pressed */
.dropdown li:focus {
  outline: none;
  background-color: #E8F1FF60;
  transition-duration: 200ms;
}
