.back-button {
  margin-right: 20px;
  margin-top: 3px;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border: 2px solid var(--lightGrayBorder);
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: url('../icons/back.svg');
  }
}
