:root {
  // toast background color
  --toastify-color-info: #cce5ff!important;
  --toastify-color-success: #fff!important;
  --toastify-color-warning: #fff3cd!important;
  --toastify-color-error: #f8d7da!important;
  --toastify-color-dark: #d6d8d9!important;

  // toast text color
  --toastify-text-color-info: #004085!important;
  --toastify-text-color-success: #222222!important;
  --toastify-text-color-warning: #856404!important;
  --toastify-text-color-error: #721c24!important;
  --toastify-text-color-dark: #1b1e21!important;

  // toast close button color
  .Toastify__toast-theme--colored.Toastify__toast--info {
    .Toastify__close-button {
      color: var(--toastify-text-color-info)!important;
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--success {
    background: var(--toastify-color-success);
    .Toastify__close-button {
      color: var(--toastify-text-color-success)!important;
    }

    .Toastify__toast-icon {
      svg {
        width: 120px;
        font-size: 80px;
        color: #7ED6C8;
      }
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--warning {
    background: var(--toastify-color-success);
    .Toastify__close-button {
      color: var(--toastify-text-color-warning)!important;
    }

    .Toastify__toast-icon {
      svg {
        width: 120px;
        font-size: 80px;
        color: var(--toastify-text-color-warning)!important;
      }
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--error {
    background: #fff;
    .Toastify__close-button {
      color: var(--toastify-text-color-error)!important;
    }

    .Toastify__toast-icon {
      svg {
        width: 120px;
        font-size: 80px;
        color: var(--toastify-text-color-error)!important;
      }
    }
  }
  .Toastify__toast-theme--dark {
    background: #fff;
    .Toastify__close-button {
      color: var(--toastify-text-color-dark)!important;
    }

    .Toastify__toast-icon {
      svg {
        width: 120px;
        font-size: 80px;
        color: var(--toastify-text-color-dark)!important;
      }
    }
  }
  .Toastify__toast-theme--colored.Toastify__toast--default {
    background: var(--toastify-color-success);
    .Toastify__close-button {
      color: var(--toastify-text-color-light);
    }

    .Toastify__toast-icon {
      svg {
        width: 120px;
        font-size: 80px;
        color: var(--toastify-text-color-light)!important;
      }
    }
  }

  .Toastify__close-button {
    position: absolute;
    right: 8px;

    svg {
      color: #999999!important;
    }
  }

  .Toastify__toast {
    border-radius: 8px;
    min-width: 350px;
    max-width: 100%;
    min-height: 200px;
    margin: 0;
  }
  
  .Toastify__toast-body {
    font-size: 24px;
    font-family: 'Stolzl Book', 'sans-serif';
    color: var( --toastify-text-color-success);
  }

  .Toastify__toast-container--top-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.7);
    top: 0;
  }

  .Toastify__toast-body > div:last-child {
    text-align: center;
    margin: 20px 0 0;
    line-height: 1.5;
  }

  .Toastify__toast-body.slim-toast > div:last-child{
    margin: 0 !important;
    text-align: left !important;
  }

  .Toastify__toast-body {
    display: flex;
    flex-direction: column;
  }

  .Toastify__toast-icon  {
    margin: 0;
    width: auto;
  }
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.swing-out-top-bck {
	-webkit-animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
	        animation: swing-out-top-bck 0.45s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-7-24 15:48:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2023-7-24 15:49:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-out-top-bck
 * ----------------------------------------
 */
 @-webkit-keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}
@keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}
