.sidebar {
  position: relative;
  width: 80px;
  max-width: 300px;
  min-width: 80px;
  background-color: var(--mainColor);
  color: var(--white);
  transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
  backface-visibility: hidden;


  &::before {
    content: '';
    max-width: 80px;
    min-width: 80px;
    height: 100vh;
    background-color: var(--mainColor);
    z-index: 0;
    position: fixed;
    z-index: 2;
    transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
    backface-visibility: hidden;
  }

  &.show {
    min-width: 300px;
    max-width: 300px;
    width: 130px;

    @media screen and (max-width: 767px) {
      min-width: 130px;
      max-width: 130px;
    }

    &:before {
      min-width: 300px;
      max-width: 300px;

      @media screen and (max-width: 767px) {
        min-width: 130px;
        max-width: 130px;
      }
    }
  }
}

.sidebar__heading {
  padding: 56px 0;
  text-align: center;
  margin-top: 20px;
  position: fixed;
  left: 25px;
  z-index: 2;

  .show & {
    margin: 0;
    left: 50px;
    
    @media screen and (max-width: 767px) {
      left: 0;
      padding: 56px 6px;
      width: 130px;
    }
  }
}

.sidebar__title {
  font-family: 'Montserrat';
  font-size: 16px;
  margin-bottom: 10px;
}

.sidebar__subtitle {
  font-size: 10px;
  display: none;
  transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
  backface-visibility: hidden;
  margin-top: 7px;

  .show & {
    display: block;
  }
}

.sidebar__item {
  padding: 14px;
  margin: 0 20px 5px;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
  backface-visibility: hidden;
  cursor: pointer;
  border-radius: 10px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  &.active {
    background-color: rgba(255,255,255, 0.1);
  }

  .show & {
    margin: 0 17px 6px;
    text-align: left;
    padding: 14px 54px 14px 14px;

    @media screen and (max-width: 767px) {
      margin: 0 0 6px;
      padding: 14px;
      display: flex;
      align-items: center;
      white-space: inherit;
      text-align: center;
    }
  }
}

.sidebar__menu {
  padding: 0;
  position: fixed;
  top: 165px;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backface-visibility: hidden;
  z-index: 2;
  height: 77vh;
  overflow-y: scroll;

  .show & {
    padding: 2px 12px;
    width: 300px;

    @media screen and (max-width: 767px) {
      width: 130px;
    }
  }
}

.sidebar__inner {
  text-decoration: none;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    // .sidebar__dropdown &  {
    //   & .sidebar__item {
    //     text-align: center;
    //     padding: 10px;
    //     white-space: unset;
    //     margin: unset;
    //     word-wrap: break-word;
    //     .sidebar__img {
    //       display: none;
    //       background-color: rgba(255, 255, 255, 0.033);
    //     }
    //   }
    // }
  }
}

.sidebar__text {
  font-size: 12px;
  display: none;
  transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
  backface-visibility: hidden;
  color: #ffffff;

  @media screen and (max-width: 767px) {
    font-size: 11px;
  }

  .active & {
    font-weight: bold;
    transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
    backface-visibility: hidden;
  }

  .show & {
    display: inline;
  }
}

.sidebar__textlink {
  font-size: 12px;
  transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
  backface-visibility: hidden;
  color: #ffffff;

  @media screen and (max-width: 767px) {
    font-size: 11px;
    .sidebar__item   & {
      display: none;
      text-align: center;
      padding: unset;
      white-space: unset;
      margin: unset;
     }
     .sidebar__dropdown & {
      display: block;
     }
  }

  .active & {
    font-weight: bold;
    transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) all;
    backface-visibility: hidden;
  }

  .show & {
    display: inline;
  }
}

.sidebar__img {
  padding: 0;
  vertical-align: middle;

  .show & {
    padding-right: 11px;
    transition: 0.3s cubic-bezier(0.16, 1, 0.3, 1) padding-right;
    backface-visibility: hidden;

    @media screen and (max-width: 767px) {
      padding-right: 0;
      margin: 0 0 10px;
    }
  }
}

.sidebar__toggler-container {
  position: fixed;
  bottom: 80px;
  left: 20px;
  margin: 0 auto;
  padding: 0;
  transition: all 0.3s ease;
  backface-visibility: hidden;
  z-index: 2;

  .show & {
    padding: 0 31px;
    left: 10px;
    right: unset;
    width: 40px;
  }
}

.sidebar__toggler {
  position: relative;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transform: rotate(180deg);
  transition: transform 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  backface-visibility: hidden;

  .show & {
    transform: rotate(0);
  }

  &::after {
    position: absolute;
    content: url('../icons/toggler.svg');
    width: 100%;

  }
}

.sidebar__list {
  list-style: none;
  position: relative;
}

.sidebar__subicon {
  position: absolute;
  top: 35px;
  right: 30px;
  z-index: 2;
  width: 21px;
  height: 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;

  .show & {
    top: 15px;

    @media screen and (max-width: 767px) {
      top: 35px;
      right: 5px;
    }
  }


  .active > & {
    transform: rotate(180deg);
  }

  &:before,
  &:after { 
    content: "";
    width: 2px;
    height: 6px;
    background-color: #fff;
    position: absolute;
  }

  &:before {
    transform: rotate(-45deg) translateY(-50%);
    top: 48%;
    left: 10px;
  }

  &:after {
    transform: rotate(45deg) translateY(-50%);
    top:48%;
    right: 10px;
  }
}

.sidebar__dropdown {
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  width: 80px;

  &.active {
    margin: 0 0 10px;
    height: auto;
    transition: all 0.3s ease;

    > .sidebar__content {
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: unset;
      }
    }
  }

  .show & {
    width: auto;
  }
}

.sidebar__sublist {
  list-style: none;
  margin: 0;

  .show & {
    margin: 0 17px 0;

    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 0;
  }
}

.sidebar__sublist-item {
  border-radius: 0;
  overflow: hidden;
  margin: 0 0 5px;

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }

  .show & {
    border-radius: 10px;
  }

  &.active {
    background-color: rgba(255,255,255, 0.1);
    font-weight: 900;
  }
}

.sidebar__sublist-link {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 14px 10px;
  font-size: 11px;
  text-align: center;
  word-wrap: break-word;

  .show & {
    padding: 14px 30px;
    text-align: left;

    @media screen and (max-width: 767px) {
      text-align: center;
      padding: 14px 10px;
    }
  }
  
  @media screen and (max-width: 767px) {
    text-align: center;
  }

  &:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.033);
  }

  &:focus {
    color: #fff;
  }
}

.sidebar__submenu {
  padding: 14px 14px 25px;

  .show & {
    padding: 14px;
  }

  .sidebar__text {
    .show & {
      @media screen and (max-width: 767px) {
        margin-right: 13px;
      }
    }
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.033);
  }
}