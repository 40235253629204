.general-evaluation-section__container {
  width: 100%;
  display: flex;
  align-items: center;
}
  
.general-evaluation-sub-section {
  padding: 0 15px 15px 50px;
  @media (max-width: 820px) {
    padding: 0 10px 15px 30px;
  }
  @media screen and (max-width: 767px) {
    padding-left: 24px;
  }
}


.general-evaluation-sub-section__content--title {
  display: grid;
  padding: 5px;
  grid-template-columns: 29% 12% 15% 15%;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 45% 1fr 1fr 1fr;
  }
}

.general-evaluation-sub-section__column-title--1 {
  grid-column: 3;
}

.general-evaluation-sub-section__column-title--2 {
  grid-column: 4;
}

.general-evaluation-sub-section__content {
  display: grid;
  grid-template-columns: 28% 12% 15% 15%;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 45% 1fr 1fr 1fr;
  }
}

.general-evaluation-sub-section__row--wrapper {
    display: grid;
    grid-template-columns: 100% 100%;
}

.general-evaluation-sub-section__column {
  padding: 5px;
}

.general-evaluation-sub-section__label {
  font-size: 12px;

  &.center {
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    position: relative;
  }

  .img__description {
    &:hover {
      + .general-evaluation-sub-section__description {
        .tooltip__description {
          visibility: visible;
        }
      }
    }
  }
  
}

.general-evaluation-sub-section__item {
  margin-top: -5px;
  font-size: 12px;
  position: relative;

  &.user-score {
    margin-top: 5px;
    font-weight: bold;
  }

  &.load {
    .boss-score {
      &:after {
        content: "";
        width: 35px;
        height: 35px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #f8f9fa;
      }
    }
  }

  .boss-score {
    width: 35px;
    height: 35px;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid transparent;
    border-radius: 10px;
    position: absolute;
    top: 0;
    pointer-events: none;
    background-color: #f8f9fa;

    &:hover {
      border: 1px solid #bbbbbb;
    }
  }
}

.general-evaluation-sub-section__description {
  padding-left: 20px;
}

.general-evaluation-sub-section__error {
  background-color: rgba(248, 215, 218, 0.4);
  border-radius: 5px;
  font-size: 10px;
  color: #721c24;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(10px);
  width: 150px;
  padding: 5px;
  text-align: center;
  z-index: 2;
}


/* Loader 2 */
.loader-2 {
  display: block;
  height: 42px;
  width: 42px;
  position: absolute;
  top: -4px;
  left: -4px;
  border-radius: 10px;
  background-color: #f8f9fb;
  animation: loader-2-1 3s linear infinite;
}
@-webkit-keyframes loader-2-1 {
	0%   { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
}
@keyframes loader-2-1 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span {
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: 0;
	height: 42px;
  width: 42px;
  clip: rect(18px, 42px, 42px, 0);
  animation: loader-2-2 1s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes loader-2-2 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span::before {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 15px;
	width: 15px;
	border: 3px solid transparent;
	border-top: 3px solid rgb(116, 109, 109);
	border-radius: 50%;
  animation: loader-2-3 1s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
}

@keyframes loader-2-3 {
	0%   { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.loader-2 span::after {
	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	margin: auto;
	height: 15px;
	width: 15px;
	border: 3px solid rgba(10, 10, 10, 0.5);
	border-radius: 50%;
}

.general-evaluation-flex {
  display: flex;
}

.general-evaluation-sub-section-actual-score {
  font-size: 12px;
  margin: 0 5px;
  font-weight: bold;
}