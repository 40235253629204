* {
  box-sizing: border-box;
}
.projects {
  .page-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .sort-table__container-table 
    tbody {
      td {
        &:first-child {
          text-transform: capitalize;
        }
      }
    } 
}
// Users' 
.projects__info-inner {
  display: flex;
  background-color: var(--lightestGray);
  padding: 30px 20px;

  p {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.projects__occupation-status {
  flex: 30%;

  span {
    font-size: 12px;
    border-radius: 12px;
    padding: 4px 8px;
    color: #ffffff; 
    text-transform: uppercase;

    &.status-occupied {
      background-color: #e83030;
    }

    &.status-vacant {
      background-color: #21af07;
    }
  }
}

.projects__occupation-ratio {
  flex: 70%;
  margin-left: 55px;

  span {
    font-size: 14px;
  }
}

//admin
.projects__add-project-form, .projects__add-member-form {
  .projects__row {
    &:not(:first-child){
      margin: 35px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.projects__row {
  display: flex;
  align-items: center;

  .projects__column {
    &:first-child {
      margin-right: 14px;
    }

    &:last-child {
      margin-left: 14px;
    }
  }
}

.projects__column {
  width: 100%;

  &.row {
    display: flex;
    align-items: center;
  }

  &.projects__column-one {
    margin: 0!important;
  }

  &.projects__column-two-unequal {
    .projects__column {
      &:first-child {
        width: 70%;
      }
  
      &:last-child {
        width: 30%;
      }
    } 
  }
}

.projects__input {
  max-width: 100%!important;
}

.projects__checkbox-wrapper {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-top: 20px;
}

.projects__add-project-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.projects__add-project-form {
  margin-top: 10px;
}

.projects__add-member-form {
  margin-top: 40px;
}

.projects__input-select {
  max-width: 100%;
}

.projects__checkbox_custom-checkbox {
  > .projects__input-checkbox {
    display: none;

    + *::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin-right: 0.3rem;
      border-radius: 5px;
      border: 1px solid var(--inputBorder);
      flex-shrink: 0;
      background-color: var(--white);
    }

    &:checked {
      + * {
        color: -var(--mainColor);
      }

      + *::before {
        content: "✓";
        color: white;
        text-align: center;
        background: var(--mainColor);
        border-color: var(--mainColor);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .projects__checkbox-checkmark {
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}

.projects__add-member-button-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.visible {
  visibility:visible;
  color: #e83030;
  font-size: small;
  margin-top: 5px;
  margin-left: 5px;
}
.not-visible {
  visibility:hidden;
}

.member-added-success {
  color: #12eb48ee;
  font-size: small;
  margin-top: 5px;
  margin-left: 5px;
}

.member-added-fail {
  color: #e83030;
  font-size: small;
  margin-top: 5px;
  margin-left: 5px;
}

.projects__info-container {
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 16px;
  }
}

@media screen and (max-width: 375px ) {
  .projects__info-inner {
    display: block;
  }

  .projects__occupation-ratio {
    margin: 20px 0px 0px;
  }
}

.projects_select {
  .css-1z0mfn1-control, .css-h2sjus-control, .css-10r4f2m-control {
    height: 1.75rem !important;
    min-height: 1.70rem !important;
    top: -2px;
  }

  .css-1fdsijx-ValueContainer, .project-select {
    font-size: 12px;
    top: -6px;
  }
}