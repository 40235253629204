.breadcrumbs {
  margin-top: 18px;
  font-size: 12px;
  color: var(--gray);
}

.breadcrumbs-list {
  list-style: none;
  display: flex;
}

.breadcrumbs-item {
  &:not(:last-child) {
    &:after {
      display: inline-block;
      content: '>';
      padding: 0 3px;
    }
  }

  a {
    &:link,
    &:visited {
      color: var(--gray);
    }
  }
}
