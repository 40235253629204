@mixin break {
  thead {
    display: none;
  }

  tr {
    display: block;
    margin-bottom: 5px;
  }

  td {
    display: block;
    position: relative;
    padding-left: 130px;
    text-align: left;
    border-bottom: 0;

    &:last-child {
      border-bottom: 1px solid grey;
    }

    &::before {
      content: attr(data-heading);
      position: absolute;
      top: 0;
      left: 0;
      width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: yellow;
      color: black;
      font-size: 0.75rem;
      padding: 0 5px;
      justify-content: center;
    }
  }
}

@mixin loading {
  .loading & {
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #cbcbcb;
    }

    &:after {
      content: '';
      position: absolute;
      width: 70%;
      height: 100%;
      top: 0;
      left: -100%;
      animation: loading 2s ease infinite;
      background: rgb(255,255,255);
      background: linear-gradient(254deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6937368697) 47%, rgba(255, 255, 255, 0) 100%);
    }
  }

  @keyframes loading {
    0% {
      left: -100%;
    }
  
    100% {
      left: 100%;
    }
  }
}