.account__row {
  display: flex;
  flex-direction: row;
  margin-bottom: 45px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 35px;
  }

  &.account__input-row {
    margin-bottom: 20px;
  };
}

.account__column {

  .account__text-row & {
    flex: 0 100%;

    @media screen and (max-width: 767px) {
      margin: 0 0 25px;
    }
  }

  .account__input-row & {
    flex: 0 100%;

    @media screen and (max-width: 767px) {
      flex: 0 100%;
    }

    &:nth-child(2) {
      padding-left: 40px;

      @media screen and (max-width: 767px) {
        padding-left: 0;
        margin: 20px 0 0;
      }
    }

    .button__save {
      @media screen and (max-width: 375px) {
        width: 100%;
      }
    }
  }
}

.account__label {
  font-size: 12px;
}

.account__item {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

.account__input {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding: 12px;
  font-size: 14px;
  border: 1px solid var(--inputBorder);
  border-radius: 10px;

  &--file {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.account__hint {
  color: #f44336;
  font-size: 10px;
  margin-top: 5px;
  margin-left: 5px;
}

.account__image {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 5px 0 30px;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 250px;
  }

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    border: 2px dashed var(--inputBorder);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #93959a;
  }

  & button[type="submit"] {
    position: absolute;
    bottom: 0;
    left: 160px;
  }
}

.account__image-info {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  width: 400px;
  bottom: -35px;
  position: absolute;
  left: 0;
  color: gray;
  line-height: 1.5;
}

.account__image-upload {
  line-height: 1.5;
}

.account__image-name {
  pointer-events: none;
  font-size: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-all;
  padding: 0 10px;
}

.account__image-preview {
  pointer-events: none;
  width: 150px;
  height: 150px;
  position: absolute;
  left: 0;
  right: 0;
  object-fit: cover;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: 250px;
  }
}

.account_image-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.4);
  font-size: 20px;
  color: #fff;
}

.account__submit-image {
  margin: 20px 0 0;
}

.account__container {
  width: 45%;
  flex: 0 45%;
  overflow: unset!important;

  @media screen and (max-width: 767px) {
    width: 100%;
    flex: 0 100%;
  }
}

.account__flex {
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
}

.account_upload-img {
  @media screen and (max-width: 767px) {
    margin: 0 0 60px;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    padding: 20px 0 10px;
  }
}