.user-evaluation__container {
  width: 100%;
}

.user-evaluation-section__heading {
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 100;
  padding: 20px 0 0 20px;
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    padding-top: 10px;
  }
}

.user-evaluation__heading {
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}

.user-evaluation__heading__inner {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    font-size: 11px;
  }
}

.user-evaluation__wrapper {
  width: 100%;
  border: 0.5px solid lightgray;
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
.user-evaluation__wrapper_inner {
  @media screen and (max-width: 767px) {
    width: 767px;
    padding: 15px;
  }
}

.user-evaluation-details__content {
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 20px;

  @media screen and (max-width: 767px) {
    display: block;
    padding: 0 0 10px;
  }

  &:not(:last-child) {
    margin: 50px;
  }
}

.user-evaluation__item {
  display: block;
  font-size: 14px;
  margin-top: 5px;
  font-weight: bold;
} 

.user-evaluation__label {
  font-size: 12px;
}

.user-evaluation__column {
  padding: 10px;
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
}

.user-evaluation-section {
  background-color: var(--lightestGray);
  padding: 10px;
  margin: 10px 25px 10px 25px;

  @media screen and (max-width: 767px) {
    margin: 0 0 10px;
  }

  &.no-margin-top {
    margin-top: -40px;
  }
}

.user-evaulation-sub-section {
  position: absolute;
}

.user-evaulation-sub-section__column--right {
  float: right;
  margin-top: -25px;
}

.user-evaulation-sub-section__label {
  font-size: 12px;
}

.img__description {
  position: absolute;
  padding: 1px 0 0 6px;
  content: url('../icons/description.svg');

  &:hover {
    cursor: pointer;
  }
}

.tooltip__description {
  visibility: hidden;
  width: 34%;
  background-color: var(--gray);
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 5px;
  position: absolute;
  z-index: 1;
  white-space: pre-wrap;
  text-align: left;

  @media screen and (max-width: 767px) {
    width: 175%;
    padding: 8px;
  }
}

.weight__bold {
  font-weight: bold;
}

.input__item {
  width: 70%;
  font-size: 12px;
  height: 35px;
  border: 1px solid var(--grayBorder);
  border-radius: 10px;
  outline: none;
  padding: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}