.dropdown-container {
  position: relative;
  padding-right: 10px;
  display: inline-block;
  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    width: 54px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .dropdown.open {
    border-color: #888;
  }

  .selected-option {
    display: flex;
    align-items: center;
    justify-content: center;
    .color-indicator {
      border-radius: 50%;
      width: 18px;
      height: 18px;
    }
  }

  .color-indicator {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 5px;
  }

  .selected-color-indicator {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #fff;
  }

  .options {
    position: absolute;
    top: 100%;
    box-shadow: 0px 2px 4px #00000029;
    background-color: #fff;
    border-radius: 4px;
    display: none;
    list-style: none;
    margin: 0 -5px 0;
    padding: 10px 10px 5px;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 65px;
    display: none;

  }
  .options.visible {
    display: grid;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
    text-align: left;
    li {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      margin-bottom: 5px;
      transition: background-color 0.2s ease;
    }
    li:hover {
     opacity: 0.5;
    }
  }
}