.members__list-container {
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  border: 1px solid #eee;

  &:not(:first-child) {
    margin-top: 7px;
  }
}

.members__wrapper {
  position: relative;
  margin-top: 30px;
}

.members__inner {
  margin-top: 22px;
}

.members__count {
  position: relative;
  flex: 40%;
}

.members__team {
  display: flex;
  flex-direction: column;
  flex: 50%;

  &-name {
    font-size: 12px;
  }

  &-total {
    font-size: 10px;
  }
}

.members {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.member {
  direction: rtl;  
  text-align: left; 
  

  &:not(:first-child) {
    padding-left: 25px; 

    .member__img {
      margin-left: -30px;
    }
  }
}

.member__img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  border: 1px solid #fff;
}

.member__count {
  position: absolute;
  top: 1px;
  left: 64px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 17px;
  width: 24px;
  height: 24px;
  font-size: 8px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.member__title {
  font-size: 16px;
}

/* CSS for the popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  text-align: center;
}

.popup-button {
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

/* Additional styles for cancel button */
.popup-button.cancel {
  background-color: #f0f0f0;
  color: #333;
}

/* Additional styles for save button */
.popup-button.save {
  background-color: #007bff;
  color: white;
}
