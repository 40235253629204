.timepicker input {
  width: 100%;
  height: 40px;
  border-color: #eaeaea;
  outline: none !important;
  color: #232932;
  font-size: 14px;
  padding: 0px 7px;
  background: url('../icons/time-icon.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;

  &[disabled] {
    cursor: default;
    background: #fff;
  }
}
